<template>
  <v-dialog
    :value="showDialog"
    no-click-animation
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title>
        <v-icon left>mdi-alert</v-icon>
        <span class="font-weight-bold">Please Confirm</span>
      </v-card-title>
      <v-card-text>
        {{ message }}

        <div class="mt-5">
          <v-btn
            @click="$emit('confirmed')"
            :loading="deleting"
            color="accent"
            class="mr-2"
            depressed
          >Confirm</v-btn>

          <v-btn
            :disabled="deleting"
            depressed
            @click="$emit('cancel')"
          >Cancel</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmDelete',

  props: {
    message: String,
    deleting: Boolean,
    showDialog: Boolean,
  }
}

</script>
